import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams,useLocation } from "react-router-dom";
const InvoicePage = () => {
  const [invoiceHtml, setInvoiceHtml] = useState('');
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const type = searchParams.get('type');
        let response;
        if(type){
          response = await axios.get(`https://prod.noorride.com/v1/ops/invoice/${id}?type=${type}`);
        }else{
          response = await axios.get(`https://prod.noorride.com/v1/ops/invoice/${id}`);
        }
        setInvoiceHtml(response.data);
      } catch (error) {
        console.error('Failed to fetch invoice:', error);
      }
    };

    fetchInvoice();
  }, []);

  return (
    <div dangerouslySetInnerHTML={{ __html: invoiceHtml }} />
  );
};

export default InvoicePage